<template>
  <b-card>
    <fieldSelector :hidden="$session.user.role === 'EDITOR'" ref="subfieldSelector" :hasGroup="false" :pageName="pageName" @addField="addField">
    </fieldSelector>
    <hr class="solid" />
    <template v-if="hasIMage">
      <transition name="component-fade">
        <b-row class="container" v-if="!showEdit">
          <div v-for="(itemList, index) in value" :key="index" class="card mx-1 box-shadow">
            <b-carousel class="carouselContent" id="carousel-crossfade" controls indicators fade :interval="0">
              <b-carousel-slide v-for="(image, imgI) in getImages(itemList[0].i)" :key="imgI">
                <template #img>
                  <img
                    width="300"
                    height="200"
                    class="d-block imagenCarousel img-fluid w-200"
                    :src="image.value"
                    alt="⤵ Click en Editar para agregar las imágenes"
                  />
                </template>
              </b-carousel-slide>
            </b-carousel>
            <div class="card-body d-flex flex-column mt-1 mb-1">
              <b-col v-for="(elem, index) in itemList" :key="index">
                <h3 v-if="elem.type.toUpperCase() !== 'IMAGES' && index < 3">
                  <b-card-text class="align-text-bottom" v-html="elem.value.substring(0, 20)"> </b-card-text>
                </h3>
              </b-col>
              <b-col>
                <b-button
                  block
                  md="6"
                  @click="edit(itemList)"
                  class="align-self-end btn btn-lg btn-block btn-primary"
                  style="margin-top: auto"
                  variant="primary"
                  >Editar
                </b-button>
              </b-col>
            </div>
          </div>
        </b-row>
      </transition>
      <b-modal hide-footer centered ok-only ok-title="Aceptar" size="lg" v-model="showEdit" name="component-fade">
        <list-card-edit
          @closeEdit="closeEdit"
          :parentField="parentField"
          :pageName="pageName"
          @onDeleteGroupField="deleteField"
          v-model="card"
          @deleteImg="deleteImg"
          :block="block"
          @onTranslate="translate"
          @onCopy="copy"
        >
        </list-card-edit>
      </b-modal>
    </template>

    <b-list-group v-else flush>
      <b-list-group-item v-for="(itemList, index) in value" :key="index">
        <b-row v-for="(elem, eindex) in itemList" :key="eindex">
          <b-col cols="12" md="12" v-if="elem.type.toUpperCase() === 'INPUT'">
            <b-form-group :label-for="elem.field">
              <h5>
                <b-row>
                  <b-col cols="8" md="9">
                    {{ elem.field }}
                  </b-col>

                  <b-col v-if="index === 0" cols="1" md="1">
                    <b-button
                      :hidden="$session.user.role === 'EDITOR'"
                      class="btn-icon rounded-circle"
                      variant="flat-danger"
                      @click="deleteGroupField(elem.field)"
                    >
                      <feather-icon icon="DeleteIcon" />
                    </b-button>
                  </b-col>

                  <b-col v-if="index === 0" cols="1" md="1">
                    <b-button class="btn-icon rounded-circle" variant="flat-primary" @click="translate(elem)">
                      <feather-icon icon="GlobeIcon" />
                    </b-button>
                  </b-col>
                  <b-col v-if="index === 0" cols="1" md="1">
                    <b-button class="btn-icon rounded-circle" variant="flat-primary" @click="copy(elem)">
                      <feather-icon icon="CopyIcon" />
                    </b-button>
                  </b-col>
                </b-row>
              </h5>
              <validation-provider #default="{ errors }" :name="elem.field" rules="required">
                <b-form-input :id="elem.field" v-model="elem.value" :placeholder="elem.placeholder || ''" :name="elem.field" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="12" v-if="elem.type.toUpperCase() === 'EDITOR'">
            <b-form-group :label-for="elem.field">
              <h5>
                <b-row>
                  <b-col cols="8" md="9">
                    {{ elem.field }}
                  </b-col>
                  <b-col v-if="index === 0" cols="1" md="1">
                    <b-button
                      :hidden="$session.user.role === 'EDITOR'"
                      class="btn-icon rounded-circle"
                      variant="flat-danger"
                      @click="deleteGroupField(elem.field)"
                    >
                      <feather-icon icon="DeleteIcon" />
                    </b-button>
                  </b-col>
                  <b-col v-if="index === 0" cols="1" md="1">
                    <b-button class="btn-icon rounded-circle" variant="flat-primary" @click="translate(elem)">
                      <feather-icon icon="GlobeIcon" />
                    </b-button>
                  </b-col>
                  <b-col v-if="index === 0" cols="1" md="1">
                    <b-button class="btn-icon rounded-circle" variant="flat-primary" @click="copy(elem)">
                      <feather-icon icon="CopyIcon" />
                    </b-button>
                  </b-col>
                </b-row>
              </h5>
              <validation-provider #default="{ errors }" :name="elem.field" rules="required">
                <quill-editor v-model="elem.value">
                  <div id="toolbar" slot="toolbar"></div>
                </quill-editor>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>

    <b-row v-if="!showEdit">
      <b-col md="2" class="mt-2">
        <b-button :disabled="$apiCall.state || value.length === 0" block variant="primary" type="button" @click="addToList">
          <feather-icon icon="CornerLeftDownIcon" />
        </b-button>
      </b-col>
      <b-col md="2" class="mt-2">
        <b-button :disabled="$apiCall.state || value.length === 0" block variant="outline-danger" type="button" @click="removeToList">
          <feather-icon icon="CornerLeftUpIcon" />
        </b-button>
      </b-col>
      <b-col md="2" class="mt-2">
        <b-button
          :hidden="$session.user.role === 'EDITOR'"
          :disabled="$apiCall.state"
          block
          variant="outline-danger"
          type="button"
          @click="$emit('deleteField', parentField)"
        >
          <feather-icon icon="DeleteIcon" />
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCarousel,
  BCarouselSlide,
  BCardText,
  BCard,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BListGroup,
  BListGroupItem,
  BModal
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
// import { BCarousel, BCarouselSlide, BCardText } from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
localize('es')

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BModal,
    BFormInput,
    BFormGroup,
    BButton,
    BListGroup,
    BListGroupItem,
    BCardCode,
    BCarousel,
    BCarouselSlide,
    BCardText,
    ValidationProvider,
    ValidationObserver,

    listCardEdit: () => import('@/views/components/pageAPI/listCardEdit.vue' /* webpackChunkName: "listCardEdit" */),
    fieldSelector: () => import('@/views/components/pageAPI/fieldSelector.vue' /* webpackChunkName: "fieldSelector" */)
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    parentField: {
      type: String,
      default: ''
    },
    pageName: {
      type: String,
      default: 'home'
    },
    block: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      required,

      showEdit: false,
      card: []
    }
  },

  computed: {
    page() {
      return this.$store.getters['storyStore/getPage'](this.pageName)
    },
    getImages() {
      return i => {
        return this.value.map(e => e.filter(l => l.type === 'IMAGES')).find(a => a.find(l => l.i === i))
      }
    },
    hasIMage() {
      return this.value.some(e => e.some(l => l?.type === 'IMAGES'))
    }
  },

  methods: {
    closeEdit(t) {
      this.showEdit = false
    },

    deleteImg(i, data) {
      const root = this.page.content.find(e => e.block === this.block)
      let elem = root.element.find(el => el.type === 'GROUP' && el.field === this.parentField)

      let elemRoot = elem.groupValue.find(e => e.find(l => l.i === this.card[0].i))
      elemRoot.forEach((e, i) => {
        if (e.value === data.value) {
          elemRoot.splice(i, 1)
        }
      })
    },

    edit(itemList) {
      this.card = this.value.find(f => f === itemList)

      this.showEdit = true
    },

    addField({ inputSelected, field }) {
      console.log('inputSelected :>> ', inputSelected)
      console.log('add field')
      // valida si existe el campo
      const campoRepetido = this.value.reduce((acc, val) => (val.find(el => el.field === field) ? true : false), false)
      if (campoRepetido) return this.$noti.warn(`El campo está duplicado`)

      // agrega los campos
      this.page.content = this.page.content.map(cont => ({
        autoTranslate: cont.autoTranslate,
        block: cont.block,
        element: cont.element.map(el => {
          if (el.field === this.parentField) {
            if (el.groupValue.length === 0)
              // if (inputSelected !== 'IMAGES')
              el.groupValue.push([
                {
                  i: 0,
                  field,
                  type: inputSelected,
                  value: ''
                }
              ])
            else
              el.groupValue.forEach((gv, i) => {
                gv.push({
                  i,
                  field,
                  type: inputSelected,
                  value: ''
                })
              })
          }

          return el
        })
      }))

      this.$emit('input', this.value)

      // limpia campos
      this.$refs.subfieldSelector.reset()
    },

    addToList() {
      const [def] = this.value.map(e => e.filter(l => l.field !== 'IMG'))
      // const [def] = this.value
      this.page.content = this.page.content.map(cont => ({
        autoTranslate: cont.autoTranslate,
        block: cont.block,
        element: cont.element.map(el => {
          if (el.field === this.parentField) {
            el.groupValue.push(def.map(e => ({ ...e, i: el.groupValue.length, value: '' })))
          }

          return el
        })
      }))
    },

    removeToList() {
      this.page.content = this.page.content.map(cont => ({
        block: cont.block,
        element: cont.element.map(el => {
          if (el.field === this.parentField) {
            el.groupValue.pop()
          }

          return el
        })
      }))
    },

    deleteGroupField(fi) {
      this.$swal({
        title: 'Eliminar?',
        text: `Eliminaremos el campo de lista: ${fi}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) this.deleteField(fi)
      })
    },

    deleteField(fi) {
      this.page.content = this.page.content.map(cont => ({
        block: cont.block,
        element: cont.element.map(el => {
          if (el.field === this.parentField) el.groupValue = el.groupValue.map(gval => gval.filter(({ field }) => field !== fi))

          return el
        })
      }))
    },

    copy(field) {
      const self = this
      this.$swal({
        title: `Copiar ${field.field}?`,
        text: 'El campo se copiará a todos los lenguajes sin traducción',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, copiar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return self.$store
            .dispatch('storyStore/copy', { selectedField: field, page: self.page })
            .then(() => self.$noti.success(`Copia lista!!`))
            .catch(() =>
              self.$swal({
                icon: 'error',
                title: 'Error de Copia',
                text: 'Ha ocurrido un error en la copia del texto.',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              })
            )
        }
      })
    },

    translate(field) {
      const self = this

      this.$swal({
        title: `Iniciar la traducción del campo ${field.field}?`,
        text: 'Traduciremos automáticamente el campo seleccionado',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, traducir',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return self.$store
            .dispatch('storyStore/translate', { selectedField: field, page: self.page })
            .then(() =>
              self.$swal({
                icon: 'success',
                title: 'Traducción Lista',
                text: 'Ha finalizado la traducción del campo, te recomendamos revisar cuidadosamente los resultados.',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              })
            )
            .catch(() =>
              self.$swal({
                icon: 'error',
                title: 'Error de Traducción',
                text: 'Ha ocurrido un error en el servicio de traducción.',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              })
            )
        }
      })
    }
  }
}
</script>

<style  scoped>
/* .imagenCarousel {
  object-fit: cover;
}
*/
.carouselContent {
  width: 250px;
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 1s ease;
}
.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
}
</style>


